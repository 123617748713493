<template>
    <v-data-table
        v-model="localModel"
        :headers="headers"
        :items="items"
        item-key="id"
        class="elevation-0 diagnoses_table"
        hide-default-footer
        disable-pagination
        disable-filtering
        disable-sort
        height="360px"
    >
    <template v-slot:item.id="{ item }">
        <v-radio-group v-model="localModel">
            <v-radio
                :key="item.id"
                :value="[item]"
            ></v-radio>
        </v-radio-group>
    </template>
    <template v-slot:item.text="{ item }">
        <v-chip small color="orange lighten-4" class="evaluation-option" @click="handleSeeText(item)">
            <v-icon color="brown" small class="mr-2">
                mdi-file-document
            </v-icon>
            <span class="body-2 brown--text text-decoration-underline text-capitalize font-weight-bold">
                {{ $t('diagnoses.actions.view') }}
            </span>
        </v-chip>
    </template>
    </v-data-table>
</template>

<script>
export default {
    name: 'DiagnosesTextsTable',
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        items: {
            type: Array,
            default: () => [],
            required: false,
        },
        itemsPerPage: {
            type: Number,
            default: 20,
            required: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
        headers: {
            type: Array,
            default: () => [],
            required: false,
        },
        model: {
            required: true,
        },
    },
    data() {
        return {
            localModel: this.model,
        }
    },
    methods: {
        handleSeeText(item) {
            this.$emit('on-see-text', item)
        }
    },
    watch: {
        localModel(newVal) {
            this.$emit('update:model', newVal)
        },
        model(value) {
            this.localModel = value
        },
    }
}
</script>

<style lang="scss">
@import '../../../../assets/style/colors.scss';

.headers-table {
    background: $purple-primary !important;
}

.diagnoses_table .v-data-table-header {
    background-color: $purple-primary;
}
.diagnoses_table .v-data-table-header .sortable.active {
    background-color: #d7d2ef;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: none !important;
}
.evaluation-option {
    cursor: pointer;
}

.text-no-wrap {
    word-break: keep-all;
}
</style>