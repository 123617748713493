<template>
    <v-container class="ma-0 pa-0">
        <v-btn
            color="grey lighten-1"
            outlined
            rounded
            elevation="0"
            small
            class="text-capitalize mb-6"
            @click="handleGoBackToAssignedEvaluations"
        >
            <v-icon class="mr-1" color="grey lighten-1" small>mdi-arrow-left</v-icon>
            <span class="grey--text font-weight-semibold">
                {{ $t('library.goBack') }}
            </span>
        </v-btn>

        <v-card v-if="!evaluationAssigned" class="w-100 py-6 my-5 text-center" rounded="lg" color="white" elevation="0">
            <h2 class="px-6 my-3">
                {{ $t('evaluate.titles.assignLecture') }}
            </h2>
            <p>
                {{ $t('evaluate.texts.assignLecture') }}
            </p>

            <section class="pa-3 d-flex flex-column align-center text-start">
                <v-col cols="12" md="7" xl="6">
                    <SimpleSelect
                        :items="currentCoursesOptions"
                        :model="currentCourse"
                        :value="currentCourse"
                        :placeholder="$t('evaluate.inputs.placeholders.chooseCourse')"
                        defaultItemValueField="id"
                        defaultItemNameField="name"
                        innerIcon="mdi-layers"
                        @new-value-selected="(id) => (currentCourse = id)"
                        withLabel
                        :label="$t('evaluate.inputs.labels.assignTo')"
                    />
                </v-col>

                <v-col cols="12" md="7" xl="6">
                    <p class="body-2 font-weight-medium grey--text darken-2 py-0 my-0">
                        {{ $t('evaluate.inputs.labels.filterTextsByGrade') }}
                    </p>
                    <v-chip v-for="item in gradeFilterOptions" :key="item.id" outlined label link @click="() => currentGradeFilterOption = item.id">
                        <v-row align-content="center" justify="center" :class="`w-100 text-center ${item.id === currentGradeFilterOption ? 'deep-purple lighten-5' : 'white'} px-6 h-100`">
                            <span :class="`${item.id === currentGradeFilterOption ? ' deep-purple--text font-weight-medium' : 'gray--text font-weight-light'} w-100 body-2`">
                                {{ item.text }}
                            </span>
                        </v-row>
                    </v-chip>

                </v-col>

                <v-col cols="12" md="7" xl="6">
                    <EvaluationsTextsTable
                        :model="selectedEvaluation"
                        :headers="evaluationTextsTableHeaders"
                        :items="currentEvaluationTexts"
                        :loading="loading"
                        @on-see-text="handleSeeText"
                        @update:model="(val) => (selectedEvaluation = val)"
                        
                    />
                </v-col>
                <v-col cols="12" md="7" xl="6" class="d-flex items-center justify-center">
                    <SimpleButton 
                        @click="handleAssignEvaluation"
                        :text="'Asignar lectura'"
                        :icon="null"
                        full-width
                        text-center
                        :loading="loading"
                        :disabled="!availableToAssign"
                    />
                </v-col>
            </section>

        </v-card>
        <v-card v-else class="w-100 py-6 my-5 text-center" rounded="lg" color="white" elevation="0">
            <v-row align-content="center" justify="center">
                <v-img :src="images.SuccessEvaluationAssignedImage" max-width="150"></v-img>
            </v-row>
            <h2 class="px-6 my-3">
                {{ $t('evaluate.messages.evaluationTextAssignedTitle') }}
            </h2>
            <p>
                {{ $t('evaluate.messages.evaluationTextAssignedMessage') }}
            </p>

            <section class="pa-3 d-flex flex-column align-center text-start">
                <v-col cols="12" md="7">
                    <SimpleSelect
                        :items="currentCoursesOptions"
                        :model="currentCourse"
                        :value="currentCourse"
                        :placeholder="$t('evaluate.inputs.placeholders.chooseCourse')"
                        defaultItemValueField="id"
                        defaultItemNameField="name"
                        innerIcon="mdi-layers"
                        @new-value-selected="(id) => (currentCourse = id)"
                        withLabel
                        label="Asignado a"
                        disabled
                    />
                </v-col>
                <v-col cols="12" md="7">
                    <SimpleSelect
                        :items="currentEvaluationTexts"
                        :model="selectedEvaluation[0]"
                        :value="selectedEvaluation[0]"
                        :placeholder="$t('evaluate.inputs.placeholders.chooseCourse')"
                        defaultItemValueField="id"
                        defaultItemNameField="title"
                        innerIcon="mdi-file-document-outline"
                        withLabel
                        label="Nombre de la lectura"
                        disabled
                    />
                </v-col>


                <v-col cols="12" md="7" class="d-flex items-center justify-center">
                    <SimpleButton 
                        @click="seeAssignments"
                        :text="'Ver asignaciones'"
                        :icon="null"
                        full-width
                        text-center
                        :loading="loading"
                    />
                </v-col>
            </section>

        </v-card>

        <!-- Modal See Text -->
        <v-dialog
            v-if="seeText"
            v-model="seeText"
            transition="dialog-bottom-transition"
            width="90%"
            max-width="900px"
            overlay-color="black"
            content-class="rounded-xl elevation-0"
        >
            <v-card 
                color="white"
                class="position-relative pa-9 d-flex flex-column align-items-center justify-content-center text-center"
            >
                <h1 class="my-3">
                    {{$t('evaluate.titles.textInformation')}}
                </h1>
                <v-row class="my-3 px-3">
                    <p class="font-weight-bold body-2 mr-4">Grado recomendado: <span class="font-weight-regular">{{ seeText.grade }}</span> </p>
                    <p class="font-weight-bold body-2 mr-4">Lexile®: <span class="font-weight-regular">{{ seeText.lexile }}</span> </p>
                    <p class="font-weight-bold body-2 mr-4">{{$t('evaluate.utils.numberOfWords')}}: <span class="font-weight-regular">{{ seeText.word_count }}</span> </p>
                </v-row>
                <article>
                    <p class="font-weight-bold body-1 mr-4 text-start">{{$t('evaluate.utils.textTitle')}}: <span class="font-weight-regular">{{ seeText.title }}</span> </p>
                    <p class="text-start body-1">
                        {{ getEvaluationTextWithoutTitle(seeText) }}
                    </p>
                </article>
                <v-btn icon elevation="0" class="position-absolute text-capitalize text-information__modal-close-button" color="black" @click="seeText = null">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-row>
                    <v-col>
                        <SimpleButton 
                            @click="downloadTextPDF(seeText)"
                            :text="'Descargar PDF'"
                            :icon="'mdi-file-pdf'"
                            text-center
                        />
                    </v-col>
                </v-row>
                
            </v-card>
        </v-dialog>
    </v-container>
    
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SimpleButton from '@/components/core/buttons/simpleButton/index.vue'
import SimpleSelect from '@/components/core/forms/selects/simpleSelect/index.vue'
import EvaluationsTextsTable from '../shared/tables/EvaluationTextTable.vue'
import SuccessEvaluationAssignedImage from '../../../assets/img/fluency/successful-evaluation-assigned.png'
import { jsPDF } from 'jspdf'

export default {
    name: 'AssignEvaluation',
    components: {
        SimpleButton,
        SimpleSelect,
        EvaluationsTextsTable
    },
    data() {
        return {
            currentCourse: 0,
            courseGradeOptions: [],
            loading: false,
            evaluations: [],
            selectedEvaluation: undefined,
            evaluationSelectedID: null,
            currentGradeFilterOption: 0,
            coursesOptions: [],
            seeText: undefined,
            evaluationAssigned: false,
            images: {
                SuccessEvaluationAssignedImage
            }
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            userID: (state) => state.userData.user.id,
            evaluationTexts: (state) => state.fluency.evaluationsTexts,
        }),
        evaluationTextsTableHeaders() {
            return [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'Nombre de la lectura',
                    align: 'start',
                    sortable: false,
                    value: 'title',
                },
                {
                    text: 'Documento',
                    align: 'center',
                    sortable: false,
                    value: 'text',
                },
                {
                    text: 'Lexile®',
                    align: 'center',
                    sortable: false,
                    value: 'lexile',
                },
                {
                    text: 'Nº de palabras',
                    align: 'center',
                    sortable: false,
                    value: 'word_count',
                },
            ]
        },
        gradeFilterOptions() {
            return [
                {
                    id: 0,
                    text: 'Todos',
                    grade: 0,
                },
                {
                    id: 1,
                    text: '1',
                    grade: 1,
                },
                {
                    id: 2,
                    text: '2',
                    grade: 2,
                },
                {
                    id: 3,
                    text: '3',
                    grade: 3,
                },
                {
                    id: 4,
                    text: '4',
                    grade: 4,
                },
                {
                    id: 5,
                    text: '5',
                    grade: 5,
                },
                {
                    id: 6,
                    text: '6',
                    grade: 6,
                },
                {
                    id: 7,
                    text: '7',
                    grade: 7,
                },
                {
                    id: 8,
                    text: '8',
                    grade: 8,
                },
            ]
        },
        currentCoursesOptions() {
            return this.courses
        },
        currentSelectedText(){
            if(!selectedEvaluation) {
                return null
            }
            return this.selectedEvaluation[0]
        }, 
        currentEvaluationTexts() {
            if(this.currentGradeFilterOption !== 0) {
                return this.evaluationTexts.filter((text) => text.grade === this.currentGradeFilterOption)
            }
            return this.evaluationTexts
        },
        availableToAssign() {
            if(!this.currentCourse) {
                return false
            }

            if(!this.selectedEvaluation || !this.selectedEvaluation[0]?.id) {
                return false
            }

            return true
        },
    },
    methods: {
        ...mapActions('fluency', ['getEvaluationTexts', 'addEvaluationAssignment']),
        handleGoBackToAssignedEvaluations() {
            this.$router.push('/teacher/evaluations/assignments')
        },
        async handleAssignEvaluation() {
            this.loading = true
            try {
                await this.addEvaluationAssignment({
                    courseId: this.currentCourse,
                    textId: this.selectedEvaluation[0].id,
                    teacherId: this.userID,
                })
                this.evaluationAssigned = true
            } catch (error) {
                this.$modal.error(error)
            } finally {
                this.loading = false
            }
        },
        handleSeeText(text) {
            this.seeText = text
        },
        seeAssignments() {
            this.evaluationAssigned = false
            this.$router.push('/teacher/evaluations/assignments')
        },
        getEvaluationTextWithoutTitle(evaluationText) {
            return evaluationText.text.split(" ").slice(evaluationText.title_words_amount, evaluationText.text.length).join(" ")
        },
        downloadTextPDF(text) {
            const doc = new jsPDF();
            doc.setFontSize(26);
            doc.text(text.title, 20, 20);
            doc.setFontSize(18);

            const textWithoutTitle = this.getEvaluationTextWithoutTitle(text)

            const lines = doc.splitTextToSize(textWithoutTitle, 180);
            let margin = 40;
            if (lines.length > 25) {
                margin = 30;  // Adjust starting margin based on the number of lines
            }
            doc.text(lines, 20, margin);
            doc.save(`${text.title}.pdf`)
        }
    },
    watch: {
        currentCourse(courseID) {
            const course = this.courses.find((course) => course.id === courseID)
            if(course) {
                const grade = this.gradeFilterOptions.find((grade) => grade.grade === course.ordinalGrade)
                this.currentGradeFilterOption = grade?.id || 0
            }
        },
    },
    async beforeMount() {
        this.loading = true

        await this.getEvaluationTexts()

        this.coursesOptions = this.courses
            .filter((course) => course.grade !== 'G0')
            .sort((a, b) => {
                if (a.ordinalGrade < b.ordinalGrade) return -1
                else if (a.ordinalGrade > b.ordinalGrade) return 1
                else {
                    // grades are equal, compare by section
                    if (a.section < b.section) return -1
                    else if (a.section > b.section) return 1
                    else return 0
                }
            })


        this.loading = false
    },
}
</script>

<style>
.text-information__modal-close-button {
    right: 42px !important;
}
</style>
